import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { JwtAuthService } from './auth/jwt-auth.service';
import { DateService } from './date.service';
import { LocalStoreService } from './local-store.service';
import { FeatureFlagService } from './feature-flag.service';
import { FeatureFlagSearchNames } from '../../state/models/feature-flag';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {
  amplitude: any;
  identifyEvent: any;
  subscribe: Subscription;

  constructor(
    private featureFlagService: FeatureFlagService,
    private localStorageService: LocalStoreService,
    private dateService: DateService,
    private jwtAuthService: JwtAuthService) {
    this.amplitude = (window as any).amplitude;
    this.identifyEvent = new this.amplitude.Identify();
  }

  get getAmplitude() {
    return this.amplitude;
  }

  init(userId?: any): void {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }

    const user = this.jwtAuthService.getUser();
    const resolvedUserId = this.formatUserId(userId || user?.id);

    const config = { sessionTimeout: 30 * 60 * 1000 }; // 30 min

    this.amplitude.init(environment.amplitude, resolvedUserId || null, config);
    const savedDeviceId = this.localStorageService.getItem('amplitude_device_id');
    if (savedDeviceId) {
      this.amplitude.setDeviceId(savedDeviceId);
    } else {
      const newDeviceId = this.amplitude.getDeviceId();
      this.localStorageService.setItem('amplitude_device_id', newDeviceId);
    }
    this.amplitude.setOptOut(false); // Evita log


    this.subscribe = this.featureFlagService.featureFlagSearchNamesEnabled$.subscribe(featureFlagSearchNamesEnabled => {
      this.setDefaultProperties(featureFlagSearchNamesEnabled);
    });
  }

  private setDefaultProperties(featureFlags: FeatureFlagSearchNames[]): void {
    if (featureFlags?.length) {
      this.addIdentifyEvent('feature_flags', featureFlags.join(','));
      this.amplitude.identify(this.identifyEvent);
    }
  }

  logout(): void {
    this.amplitude.setUserId(null); // Remove o User ID
    this.amplitude.setSessionId(Date.now()); // Força uma nova sessão
    this.localStorageService.setItem('amplitude_device_id', null); // Remove o Device ID armazenado
  }

  private formatUserId(userId: any): string | null {
    if (!userId) return null;
    return userId.toString().padStart(5, '0');
  }

  addIdentifyEvent(index: IdentifyNameAmplitude, value: any) {
    if (typeof value === 'number') {
      this.identifyEvent.add(index, value); // Incrementa números
    } else {
      this.identifyEvent.set(index, value); // Define strings, arrays, etc.
    }
  }

  setIdentify() {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const browserWidth = window.innerWidth;
    const browserHeight = window.innerHeight;

    this.addIdentifyEvent('screen_resolution', `${ screenWidth }x${ screenHeight }`);
    this.addIdentifyEvent('browser_size', `${ browserWidth }x${ browserHeight }`);

    const user = this.jwtAuthService.getUser();
    if (user) {
      let age: any;
      const { personProperties } = user;
      if (personProperties) {
        const { birthdate, biologicalSex } = personProperties;
        age = this.dateService.diffByYears(birthdate);
        if (birthdate) {
          this.addIdentifyEvent('biological_sex', biologicalSex);
        }
        if (age) {
          this.addIdentifyEvent('age', age);
        }
      }
    }
    this.amplitude.identify(this.identifyEvent);
  }

  sendEvent(eventName: EventNameAmplitude, props?: Record<string, any>) {
    this.amplitude.track(
      eventName,
      props
    );
  }
}

export interface TrackInfos {
  domain: string;
  location: string;
  title: string;
  path: string;
}

type IdentifyNameAmplitude =
  'feature_flags' |
  'browser_size' |
  'screen_resolution' |
  'biological_sex' |
  'age' |
  'ho_id' |
  'ho_name' |
  'rh_id' |
  'rh_name';

type EventNameAmplitude =
  'patient_summary_evaluation_clicked' |
  'ai_assistant_chat_opened' |
  'ai_assistant_suggested_question_clicked' |
  'ai_assistant_message_sent' |
  'ai_assistant_response_evaluation_sent' |
  'ai_assistant_response_evaluation_reasons_sent' |
  'ai_assistant_customer_support_redirected' |
  'ai_assistant_go_to_link_clicked' |
  'cell_phone_code_validated' |
  'terms_accept_clicked' |
  'terms_display_clicked' |
  'url_signature_failed' |
  'cell_phone_is_valid' |
  'validation_failed' |
  'onboarding_finished' |
  'answer_created' |
  'answer_updated' |
  'form_submitted' |
  'previous_exams_skipped' |
  'previous_exams_sent' |
  'exams_uploaded' |
  'new_exams_request_downloaded' |
  'contact_support_clicked' |
  'patient_summary_viewed' |
  'patient_summary_tab_clicked' |
  'patient_summary_referral_button_clicked' |
  'patient_summary_referral_downloaded' |
  'patient_summary_self_care_clicked' |
  'women_area_call_button_clicked' |
  'women_area_page_viewed' |
  'page_viewed';
